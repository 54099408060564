/* eslint-disable max-len */
import _ from 'lodash';
import moment from 'moment';
import { COMMUNICATION_STATUSES, CUSTOM_FIELDS_TYPES, EAGLE_EYE_CUSTOM_FIELD_DTO, MATCH_TAG_MODE, OFFICE_MODE_PROGRAM_TYPES, RESERVATION_STATUSES, MOBILE_UDID_STATES, LOCKS_OPEN_MODE, COMPANY_NOTIFICATIONS_GATEWAY_STATES, COMPANY_NOTIFICATIONS_BATTERY_STATES, COMPANY_NOTIFICATIONS_REPEAT_STATES, COMPANY_NOTIFICATIONS_ATLAS_STATES, COMPANY_NOTIFICATIONS_BATTERY_LEVELS } from './consts';
import { elaborateCustomFieldsEntityAttributes, elaborateSmartLockTroubleShootingId, formatDomainHostNameToDomainName, formatDomainHostNameToFriendlyName, formatOutputCustomFieldValue, getDisplayCredits, getMonthlyBurnRate } from './utils';

export const GUEST = 'GUEST';
export const DOMAIN = 'DOMAIN';
export const GUEST_TAG = 'GUEST_TAG';
export const LOCK_TAG = 'LOCK_TAG';
export const LOCK = 'LOCK';
export const CREDENTIAL = 'CREDENTIAL';
export const SETTING = 'SETTING';
export const APPLICATION_SETTING = 'APPLICATION_SETTING';
export const NOTIFICATION_SETTING = 'NOTIFICATION_SETTING';
export const LOG = 'LOG';
export const TAG_DATES = 'TAG_DATES';
export const RESERVATION_DATES = 'RESERVATION_DATES';
export const INVITATION_DATES = 'INVITATION_DATES';
export const RESERVATION = 'RESERVATION';
export const RESOURCE = 'RESOURCE';
export const RESOURCE_FORM_INIT = 'RESOURCE_FORM_INIT';
export const RESOURCE_FORM_EDIT_INIT = 'RESOURCE_FORM_EDIT_INIT';
export const EVENT = 'EVENT';
export const INIT_EVENT_FORM = 'INIT_EVENT_FORM';
export const CREDENTIAL_GUEST = 'CREDENTIAL_GUEST';
export const PLATFORM_USER = 'PLATFORM_USER';
export const OFFICE_MODE_PROGRAM = 'OFFICE_MODE_PROGRAM';
export const INVITATION = 'INVITATION'
export const REGISTRY_ITEM = 'REGISTRY_ITEM';
export const COMMUNICATION = 'COMMUNICATION';
export const ACCESS_PROFILE = 'ACCESS_PROFILE';
export const WALLET_SUBSCRIPTION = 'WALLET_SUBSCRIPTION';
export const TIME_PROFILE = 'TIME_PROFILE';
export const TIME_RANGE = 'TIME_RANGE';
export const CARD_WITH_PROFILE = 'CARD_WITH_PROFILE';
export const TICKET = 'TICKET';
export const TICKET_CATEGORY = 'TICKET_CATEGORY';
export const LOCK_GROUP = 'LOCK_GROUP';
export const AREA = 'AREA';
export const AREA_OCCUPANCY = 'AREA_OCCUPANCY';
export const AREA_OCCUPANCY_HOUR = 'AREA_OCCUPANCY_HOUR';


export function formatInputData(entity, data) {
  switch (entity) {
    case WALLET_SUBSCRIPTION: {
      return {
        ...data,
        burnRateDaily: getDisplayCredits(data.burnRate),
        burnRate: getMonthlyBurnRate(data.burnRate),
        fixedPrice: getDisplayCredits(data.fixedPrice),
      };
    }
    case TIME_RANGE: {
      const timeIntervalFrom = data && data.timeInterval && data.timeInterval.from ? moment().startOf('day').add(data.timeInterval.from, 'seconds').valueOf() : moment().startOf('day').add(0, 'seconds').valueOf();
      const timeIntervalTo = data && data.timeInterval && data.timeInterval.to ? moment().startOf('day').add(data.timeInterval.to, 'seconds').valueOf() : moment().startOf('day').add(0, 'seconds').valueOf();
      return {
        daysOfTheWeek: data && data.daysOfTheWeek ? data.daysOfTheWeek : null,
        timeIntervalFrom,
        timeIntervalTo,
      };
    }
    case OFFICE_MODE_PROGRAM: {
      const index = data.index;
      const timeIntervalFrom = data && data.timeInterval && data.timeInterval.from ? moment().startOf('day').add(data.timeInterval.from, 'seconds').valueOf() : moment().startOf('day').add(0, 'seconds').valueOf();
      const timeIntervalTo = data && data.timeInterval && data.timeInterval.to ? moment().startOf('day').add(data.timeInterval.to, 'seconds').valueOf() : moment().startOf('day').add(0, 'seconds').valueOf();
      const programType = data.automatic ? OFFICE_MODE_PROGRAM_TYPES.AUTOMATIC : OFFICE_MODE_PROGRAM_TYPES.SEMIAUTOMATIC;
      return {
        daysOfTheWeek: data.daysOfTheWeek,
        enableOnFirstAccess: data.enableOnFirstAccess,
        programStartTime: timeIntervalFrom,
        programEndTime: timeIntervalTo,
        programType,
      };
    }
    case INVITATION: {
      const invitationCredentialTimeIntervalFrom = moment().startOf('day').add(data.timeInterval.from, 'seconds').valueOf();
      const invitationCredentialTimeIntervalTo = moment().startOf('day').add(data.timeInterval.to, 'seconds').valueOf();
      const invitationValidityDateFrom = moment(data.dateInterval.from).valueOf();
      const invitationValidityDateTo = moment(data.dateInterval.to).valueOf();
      const isExpired = moment(invitationValidityDateTo).isBefore(moment());
      const isNotYetActive = moment(invitationValidityDateFrom).isAfter(moment());
      let status = RESERVATION_STATUSES.ACTIVE;
      if (isExpired) {
        status = RESERVATION_STATUSES.EXPIRED;
      } else if (isNotYetActive) {
        status = RESERVATION_STATUSES.NOT_YET_ACTIVE;
      }
      return {
        ...data,
        invitationCredentialTimeIntervalFrom,
        invitationCredentialTimeIntervalTo,
        invitationStatus: status,
      };
    }
    case COMMUNICATION: {
      const momentDateFrom = moment(data.fromDate);
      const momentDateTo = moment(data.toDate);
      const isExpired = momentDateTo.isBefore(moment());
      const isNotYetActive = momentDateFrom.isAfter(moment());
      let status = COMMUNICATION_STATUSES.VISIBLE;
      if (isExpired) {
        status = COMMUNICATION_STATUSES.NOT_VISIBLE;
      } else if (isNotYetActive) {
        status = COMMUNICATION_STATUSES.NOT_YET_VISIBLE;
      }
      return {
        ...data,
        status,
      };
    }
    case LOCK_GROUP: {
      const { groupName, smartLocks, groupMasterLockId } = data;
      return {
        groupName: _.startCase(groupName),
        enabled: false,
        smartLocks,
        id: groupName,
        groupMasterLockId,
        serialNumber: groupName,
        model: _.first(smartLocks) && _.first(smartLocks).model ? _.first(smartLocks).model: 'LIBRA',
        configuration: LOCKS_OPEN_MODE.GROUP_OPEN,
      }
    };
    case ACCESS_PROFILE: {
      const timeIntervalFrom = moment().startOf('day').add(data.timeInterval.from, 'seconds').valueOf();
      const timeIntervalTo = moment().startOf('day').add(data.timeInterval.to, 'seconds').valueOf();
      const credentialDateFrom = data.dateInterval.from;
      const credentialDateTo = data.dateInterval.to;
      const dateIntervalTimeFrom = data.dateInterval.from;
      const dateIntervalTimeTo = data.dateInterval.to;
      const isExpired = moment(credentialDateTo).isBefore(moment());
      const isNotYetActive = moment(credentialDateFrom).isAfter(moment());
      const additionalTimeRange = data.additionalTimeRange && data.additionalTimeRange.timeInterval ? {
        ...data.additionalTimeRange,
        timeIntervalFrom: moment().startOf('day').add(data.additionalTimeRange.timeInterval.from, 'seconds').valueOf(),
        timeIntervalTo: moment().startOf('day').add(data.additionalTimeRange.timeInterval.to, 'seconds').valueOf(),
      } : null;
      let status = RESERVATION_STATUSES.ACTIVE;
      if (isExpired) {
        status = RESERVATION_STATUSES.EXPIRED;
      } else if (isNotYetActive) {
        status = RESERVATION_STATUSES.NOT_YET_ACTIVE;
      }
      return {
        ...data,
        timeIntervalFrom,
        timeIntervalTo,
        dateIntervalTimeFrom,
        dateIntervalTimeTo,
        credentialTimeframe: {
          startDate: data.dateInterval.from,
          endDate: data.dateInterval.to,
        },
        daysOfTheWeek: data.daysOfTheWeek,
        credentialDateFrom,
        credentialDateTo,
        status,
        additionalTimeRange: additionalTimeRange,
      };
    }
    case RESERVATION: {
      const momentDateFrom = moment(data.fromDate);
      const momentDateTo = moment(data.toDate);

      const isExpired = momentDateTo.isBefore(moment());
      const isNotYetActive = momentDateFrom.isAfter(moment());
      let status = RESERVATION_STATUSES.ACTIVE;
      if (isExpired) {
        status = RESERVATION_STATUSES.EXPIRED;
      } else if (isNotYetActive) {
        status = RESERVATION_STATUSES.NOT_YET_ACTIVE;
      }
      return {
        ...data,
        timeFrom: data.fromDate,
        timeTo: data.toDate,
        resource: data.resource,
        days: momentDateTo.diff(momentDateFrom, 'days'),
        status,
      };
    }
    case DOMAIN: {
      const { hostName } = data;
      return {
        name: _.startCase(formatDomainHostNameToFriendlyName(hostName)),
        domainName: formatDomainHostNameToDomainName(hostName),
        ...data,
      };
    }
    case GUEST: {
      const userTag = _.find(data.tags, tag => tag.type === 'user');
      const tags = _.filter(data.tags, tag => tag.type !== 'user');
      const isGuestManager = _.size(data.roles) > 1;
      const customFields = elaborateCustomFieldsEntityAttributes(data.customFields, data.customAttributes);
      return {
        ...data,
        userTag,
        isGuestManager,
        customFields,
        accessProfileId: data.accessProfileId,
        roleIds: data.roleIds,
        tags: _.map(tags, tag => ({
          ...tag,
          dateFrom: tag.from,
          dateTo: tag.to,
          timeFrom: tag.from,
          timeTo: tag.to,
        }),
        ),
        email: data.withoutEmail ? null: data.email,
      };
    }
    case LOCK: {
      const filteredAttributes = _.omitBy(data.customAttributes, _.isEmpty);
      const customFields = elaborateCustomFieldsEntityAttributes(data.customFields, filteredAttributes);
      const troubleShootingArticleId = elaborateSmartLockTroubleShootingId(data);
      const lockAccessories = _.filter(data.accessories, accessory => accessory.entityId === data.id);
      const lockHasCamera = _.find(_.keys(customFields), customFieldKey => customFieldKey === EAGLE_EYE_CUSTOM_FIELD_DTO.name && customFields[customFieldKey]);
      const lockTag = {
        color: '#ba68c8',
        name: data && data.name ? data.name : '',
        type: 'lock',
      };
      return {
        ...data,
        lockTag,
        accessories: lockAccessories,
        customFields,
        lockHasCamera,
        troubleShootingArticleId,
        cameraId: customFields[EAGLE_EYE_CUSTOM_FIELD_DTO.name],
      };
    }
    case CREDENTIAL: {
      const timeIntervalFrom = moment().startOf('day').add(data.timeInterval.from, 'seconds').valueOf();
      const timeIntervalTo = moment().startOf('day').add(data.timeInterval.to, 'seconds').valueOf();
      const credentialDateFrom = moment(data.dateInterval.from).valueOf();
      const credentialDateTo = moment(data.dateInterval.to).valueOf();
      const dateIntervalTimeFrom = moment(data.dateInterval.from).valueOf();
      const dateIntervalTimeTo = moment(data.dateInterval.to).valueOf();
      const isExpired = moment(credentialDateTo).isBefore(moment());
      const isNotYetActive = moment(credentialDateFrom).isAfter(moment());
      const additionalTimeRange = data.additionalTimeRange && data.additionalTimeRange.timeInterval ? {
        ...data.additionalTimeRange,
        timeIntervalFrom: moment().startOf('day').add(data.additionalTimeRange.timeInterval.from, 'seconds').valueOf(),
        timeIntervalTo: moment().startOf('day').add(data.additionalTimeRange.timeInterval.to, 'seconds').valueOf(),
      } : null;
      let status = RESERVATION_STATUSES.ACTIVE;
      if (isExpired) {
        status = RESERVATION_STATUSES.EXPIRED;
      } else if (isNotYetActive) {
        status = RESERVATION_STATUSES.NOT_YET_ACTIVE;
      }
      return {
        ...data,
        timeIntervalFrom,
        timeIntervalTo,
        dateIntervalTimeFrom,
        dateIntervalTimeTo,
        accessProfileId: data.accessProfileId,
        credentialTimeframe: {
          startDate: data.dateInterval.from,
          endDate: data.dateInterval.to,
        },
        daysOfTheWeek: data.daysOfTheWeek,
        credentialDateFrom,
        credentialDateTo,
        status,
        additionalTimeRange: additionalTimeRange,
      };
    }
    case CREDENTIAL_GUEST: {
      const credentialDateFrom = moment(data.fromDate).valueOf();
      const credentialDateTo = moment(data.toDate).valueOf();
      const isExpired = moment(credentialDateTo).isBefore(moment());
      const isNotYetActive = moment(credentialDateFrom).isAfter(moment());
      let status = RESERVATION_STATUSES.ACTIVE;
      if (isExpired) {
        status = RESERVATION_STATUSES.EXPIRED;
      } else if (isNotYetActive) {
        status = RESERVATION_STATUSES.NOT_YET_ACTIVE;
      }
      return {
        ...data,
        credentialDateFrom,
        credentialDateTo,
        status,
      };
    }
    case LOG: {
      let payload;
      try {
        payload = data.payload ? JSON.parse(data.payload) : {};
      } catch (ex) {
        payload = data.payload;
      }
      return {
        ...data,
        payload,
      };
    }
    case RESOURCE: {
      const dataFormatted = data;
      const timeIntervalFrom = moment().startOf('day').add(data.timeInterval.from, 'seconds').valueOf();
      const timeIntervalTo = moment().startOf('day').add(data.timeInterval.to, 'seconds').valueOf();
      dataFormatted.resourceSmartLockTags = _.map(data.resourceSmartLockTags, tag => ({ id: tag }));
      dataFormatted.resourceUserTags = _.map(data.resourceUserTags, tag => ({ id: tag }));
      return {
        ...dataFormatted,
        credentialTimeframe: {
          startDate: dataFormatted.dateInterval.from,
          endDate: dataFormatted.dateInterval.to,
        },
        timeIntervalFrom,
        timeIntervalTo,
      };
    }

    case INIT_EVENT_FORM: {
      return {
        dateFrom: moment(data.fromDate),
        dateTo: moment(data.toDate),
        timeFrom: moment(data.fromDate).valueOf(),
        timeTo: moment(data.toDate).valueOf(),
        location: data.address || '',
        ...data,
      };
    }
    
    case TICKET: {
      return {
        ...data,
      };
    }
    
    case TICKET_CATEGORY: {
      return {
        ...data,
      };
    }
    
    case AREA: {
      return {
        ...data,
      };
    }
    
    case AREA_OCCUPANCY: {
      return {
        ...data,
      };
    }
    
    case AREA_OCCUPANCY_HOUR: {
      return {
        ...data,
      };
    }
    
    default: return {};
  }
}

export function formatOutputData(entity, data) {
  switch (entity) {
    case CARD_WITH_PROFILE: {
      return {
        type: data.type,
        deviceId: data.deviceId,
        notes: data.notes,
        userId: data.userId,
        passageMode: data.passageMode,
      }
    }
    case OFFICE_MODE_PROGRAM: {
      const timeIntervalFrom = (moment(data.programStartTime).hours() * 3600) + (moment(data.programStartTime).minutes() * 60) + moment(data.programStartTime).seconds();
      const timeIntervalTo = (moment(data.programEndTime).hours() * 3600) + (moment(data.programEndTime).minutes() * 60) + moment(data.programEndTime).seconds();
      const automatic = data.programType === OFFICE_MODE_PROGRAM_TYPES.AUTOMATIC;
      const semiAutomatic = data.programType === OFFICE_MODE_PROGRAM_TYPES.SEMIAUTOMATIC;
      return {
        automatic,
        semiAutomatic,
        daysOfTheWeek: data.daysOfTheWeek,
        enableOnFirstAccess: data.enableOnFirstAccess,
        timeInterval: {
          from: timeIntervalFrom,
          to: timeIntervalTo,
        },
      };
    }
    case INVITATION: {
      const fromDate = data.validityDateFrom;
      const toDate = data.validityDateTo;
      const validityStart = moment(fromDate).hour(data.validityTimeFrom.hour()).minutes(data.validityTimeFrom.minutes()).valueOf();
      const validityEnd = moment(toDate).hour(data.validityTimeTo.hour()).minutes(data.validityTimeTo.minutes()).valueOf();
      const timeIntervalFrom = (moment(data.timeIntervalFrom).hours() * 3600) + (moment(data.timeIntervalFrom).minutes() * 60) + moment(data.timeIntervalFrom).seconds();
      const timeIntervalTo = (moment(data.timeIntervalTo).hours() * 3600) + (moment(data.timeIntervalTo).minutes() * 60) + moment(data.timeIntervalTo).seconds();
      const smartLocks = data.selectedLocks && data.selectedLocks.data ? data.selectedLocks.data : [];
      const smartLockIds = _.map(smartLocks, lock => lock.id);
      const daysOfTheWeek = data.daysOfTheWeek;
      return {
        dateInterval: {
          from: validityStart,
          to: validityEnd,
        },
        timeInterval: {
          from: timeIntervalFrom,
          to: timeIntervalTo,
        },
        name: data.name,
        smartLockIds,
        daysOfTheWeek,
        numberOfDevices: data.numberOfDevices,
      };
    }
    case COMMUNICATION: {
      const fromDate = moment(data.communicationTimeFrame.startDate).startOf('day').valueOf();
      const toDate = moment(data.communicationTimeFrame.endDate).endOf('day').valueOf();
      return {
        ..._.omit(data, 'communicationTimeFrame'),
        fromDate,
        toDate,
      };
    }
    case REGISTRY_ITEM: {
      const selectedGuest = data && data.selectedGuestTags && _.isArray(data.selectedGuestTags) && !_.isEmpty(data.selectedGuestTags) ? _.first(data.selectedGuestTags) : null;
      return {
        ..._.omit(data, ['selectedGuestTags', 'guestTags', 'recipient', 'createdBy']),
        recipientId: selectedGuest ? selectedGuest.id : null,
      };
    }
    case RESERVATION_DATES: {
      const momentTimeFrom = moment(data.startTime);
      const momentTimeTo = moment(data.endTime);
      return {
        dateFrom: moment(data.dateFrom).hour(momentTimeFrom.hour()).minute(momentTimeFrom.minute()).valueOf(),
        dateTo: moment(data.dateTo).hour(momentTimeTo.hour()).minute(momentTimeTo.minute()).valueOf(),
      };
    }

    case INVITATION_DATES: {
      return {
        validityDateFrom: moment(data.dateFrom).valueOf(),
        validityDateTo: moment(data.dateTo).valueOf(),
      };
    }
    case RESERVATION: {
      return {
        userId: data.reservationUserId,
        fromDate: data.dateFrom,
        checkInNotNecessary: data.checkInNotNecessary,
        toDate: data.dateTo,
        resource: {
          id: data.resourceId,
        },
        title: data.title,
        description: data.description,
      };
    }
    case EVENT: {
      const momentTimeFrom = moment(data.timeFrom);
      const momentTimeTo = moment(data.timeTo);
      return {
        fromDate: moment(data.dateFrom).hour(momentTimeFrom.hour()).minute(momentTimeFrom.minute()).valueOf(),
        toDate: moment(data.dateTo).hour(momentTimeTo.hour()).minute(momentTimeTo.minute()).valueOf(),
        title: data.title,
        description: data.description,
        id: data.id,
        address: data.location,
        link: data.link,
      };
    }
    case GUEST: {
      const groupedTags = _.partition(data.tags, tag => tag.timeFrom && tag.timeTo);
      const stateCustomFields = data.customFields;
      const customAttributes = _.reduce(stateCustomFields, (acc, curr) => {
        const value = formatOutputCustomFieldValue(data[curr.keyValue], curr.type);
        acc = {
          ...acc,
          [curr.keyValue]: value,
        };
        return acc;
      }, {});
      
      let mobileUuidState = data.mobileUuidState;
      if (_.includes([MOBILE_UDID_STATES.SET, MOBILE_UDID_STATES.RESET], data.mobileUuidState)) {
        mobileUuidState = undefined;
      }

      return {
        id: data.id,
        email: data.email,
        username: data.email || data.username,
        firstname: data.firstname,
        lastname: data.lastname,
        password: data.password,
        role: data.role,
        roleIds: data.roleIds,
        companyName: data.companyName,
        mobileUuidState: mobileUuidState,
        accessProfileId: data.accessProfileId && data.accessProfileId.length ? data.accessProfileId : 0,
        userTagIds: groupedTags[1] ? _.map(groupedTags[1], val => val.id) : [],
        userTagIdsWithTime: groupedTags[0] ? _.map(groupedTags[0], (tag) => {
          const timeFrom = moment(tag.timeFrom);
          const timeTo = moment(tag.timeTo);
          const tagIntervalTo = moment(tag.dateTo).hour(timeTo.hour()).minute(timeTo.minute()).second(0);
          const tagIntervalFrom = moment(tag.dateFrom).hour(timeFrom.hour()).minute(timeFrom.minute()).second(0);

          return {
            id: tag.id,
            interval: {
              from: tagIntervalFrom.valueOf(),
              to: tagIntervalTo.valueOf(),
            },
          };
        }) : [],
        customAttributes,
        companyNotificationsEnabled: data.companyNotificationsEnabled,
        using2FA: data.using2FA,
      };
    }
    case PLATFORM_USER: {
      return {
        id: data.id,
        email: data.email,
        firstname: data.firstname,
        lastname: data.lastname,
        password: data.password,
        role: data.role,
        roleIds: data.roleIds,
        companyName: data.companyName,
        username: data.username,
        using2FA: data.using2FA,
        twoFactorType: data.twoFactorType,
        companyNotificationsEnabled: data.companyNotificationsEnabled?data.companyNotificationsEnabled:false,
      };
    }
    case GUEST_TAG:
      return {
        id: data.id,
        name: data.name,
        type: data.type ? data.type.name : undefined,
        color: data.color ? data.color.hex : undefined,
      };
    case LOCK_TAG:
      return {
        id: data.id,
        name: data.name,
        type: data.type ? data.type.name : undefined,
        color: data.color ? data.color.hex : undefined,
      };
    case LOCK: {
      const stateCustomFields = data.customFields;
      const customAttributes = _.reduce(stateCustomFields, (acc, curr) => {
        const value = formatOutputCustomFieldValue(data[curr.keyValue], curr.type);
        acc = {
          ...acc,
          [curr.keyValue]: value,
        };
        return acc;
      }, {});
      return {
        id: data.id,
        lockTagIds: _.map(data.tags, val => val.id),
        name: data.name,
        customAttributes,
      };
    }
    case TAG_DATES: {
      const timeFrom = moment(data.timeFrom);
      const timeTo = moment(data.timeTo);
      return {
        dateFrom: moment(data.dateFrom).hours(timeFrom.hours()).minutes(timeFrom.minutes()),
        dateTo: moment(data.dateTo).hours(timeTo.hours()).minutes(timeTo.minutes()),
      };
    }
    case TIME_PROFILE: {
      let lockTags = data.lockTags;
      if (!_.isArray(lockTags)) {
        lockTags = [lockTags];
      }
      const timeIntervalFrom =
        (moment(data.timeIntervalFrom).hours() * 3600) + (moment(data.timeIntervalFrom).minutes() * 60) + moment(data.timeIntervalFrom).seconds();
      const timeIntervalTo =
        (moment(data.timeIntervalTo).hours() * 3600) + (moment(data.timeIntervalTo).minutes() * 60) + moment(data.timeIntervalTo).seconds();
      
        let timeProfileData = {
        ...data,
        dateInterval: {
          from: moment(data.credentialTimeframe.startDate).startOf('day').valueOf(),
          to: moment(data.credentialTimeframe.endDate).endOf('day').valueOf(),
        },
        timeInterval: {
          from: timeIntervalFrom,
          to: timeIntervalTo,
        },
        name: data.name,
        daysOfTheWeek: data.daysOfTheWeek,
        lockTagIds: _.map(lockTags, val => val.id),
        lockTagMatchingMode: data.lockTagMatchingMode,
        openOnPrivacy: data.openOnPrivacy || false,
        alwaysOpen: data.alwaysOpen || false,
        holidays: data.holidays || false,
      };
      if (data.additionalTimeRange_additional && !_.isEmpty(data.additionalTimeRange_additional)) {
        const additionaTimeRange = _.first(data.additionalTimeRange_additional);
        const timeIntervalFrom = (moment(additionaTimeRange.timeIntervalFrom).hours() * 3600) + (moment(additionaTimeRange.timeIntervalFrom).minutes() * 60) + moment(additionaTimeRange.timeIntervalFrom).seconds();
        const timeIntervalTo = (moment(additionaTimeRange.timeIntervalTo).hours() * 3600) + (moment(additionaTimeRange.timeIntervalTo).minutes() * 60) + moment(additionaTimeRange.timeIntervalTo).seconds();
        const formattedTimeRange = {
            timeInterval: {
              from: timeIntervalFrom,
              to: timeIntervalTo,
            },
            daysOfTheWeek: additionaTimeRange.daysOfTheWeek,
        };
        timeProfileData = {
          ...timeProfileData,
          additionalTimeRange: formattedTimeRange,
        }
      }
      return timeProfileData;
    }
    case TIME_RANGE: {
      const timeIntervalFrom =
        (moment(data.timeIntervalFrom).hours() * 3600) + (moment(data.timeIntervalFrom).minutes() * 60) + moment(data.timeIntervalFrom).seconds();
      const timeIntervalTo =
        (moment(data.timeIntervalTo).hours() * 3600) + (moment(data.timeIntervalTo).minutes() * 60) + moment(data.timeIntervalTo).seconds();
        return {
          timeInterval: {
            from: timeIntervalFrom,
            to: timeIntervalTo,
          },
          daysOfTheWeek: data.daysOfTheWeek,
          holidays: data.holidays
        }
    }
    case CREDENTIAL: {
      const timeIntervalFrom =
        (moment(data.timeIntervalFrom).hours() * 3600) + (moment(data.timeIntervalFrom).minutes() * 60) + moment(data.timeIntervalFrom).seconds();
      const timeIntervalTo =
        (moment(data.timeIntervalTo).hours() * 3600) + (moment(data.timeIntervalTo).minutes() * 60) + moment(data.timeIntervalTo).seconds();
      const dateIntervalTimeFrom = moment(data.credentialTimeframe.startDate)
        .hour(moment(data.dateIntervalTimeFrom).hours())
        .minute(moment(data.dateIntervalTimeFrom).minutes()).startOf('minute').valueOf();
      const dateIntervalTimeTo = moment(data.credentialTimeframe.endDate)
        .hour(moment(data.dateIntervalTimeTo).hours())
        .minute(moment(data.dateIntervalTimeTo).minutes()).endOf('minute').valueOf();
      let guestTags = data.guestTags;
      if (!_.isArray(guestTags)) {
        guestTags = [guestTags];
      }

      let lockTags = data.lockTags;
      if (!_.isArray(lockTags)) {
        lockTags = [lockTags];
      }
      return {
        id: data.id,
        name: data.name,
        description: data.description,
        lockTagIds: _.map(lockTags, val => val.id),
        guestTagIds: _.map(guestTags, val => val.id),
        lockTagMatchingMode: data.lockTagMatchingMode,
        guestTagMatchingMode: data.guestTagMatchingMode,
        notes: data.notes,
        dateInterval: {
          from: dateIntervalTimeFrom,
          to: dateIntervalTimeTo,
        },
        timeInterval: {
          from: timeIntervalFrom,
          to: timeIntervalTo,
        },
        daysOfTheWeek: data.daysOfTheWeek,
        additionalTimeRange: data.additionalTimeRange,
        openOnPrivacy: data.openOnPrivacy || false,
        alwaysOpen: data.alwaysOpen || false,
        holidays: data.holidays || false,
      };
    }
    case LOG:
      return {
        ...data,
        freeTextSearch: data.freeTextSearch ? data.freeTextSearch : undefined,
      };
    case SETTING:
      return {
        name: data.name,
        email: data.email,
        address: data.address,
        city: data.city,
        fax: data.fax,
        logoUrl: data.logoUrl,
        phone: data.phone,
        province: data.province,
        state: data.state,
        taxCode: data.taxCode,
        vat: data.vat,
        zipCode: data.zipCode,
        themeName: data.themeName,
        logo: data.logo,
        mainFirstname: data.mainFirstname,
        mainLastname: data.mainLastname,
        mainEmail: data.mainEmail,
        mobileUuidDefaultState: data.mobileUuidDefaultState,
        guestInvitationLimitDefault: data.guestInvitationLimitDefault,
      };
    case APPLICATION_SETTING:
      return {
        appUrl: data.appUrl,
        privacyUrl: data.privacyUrl,
        termsUrl: data.termsUrl,
        helpCenter: data.helpCenter,
        androidApp: data.androidApp,
        iosApp: data.iosApp,
      };
    case NOTIFICATION_SETTING:
      return {
        gatewayOfflineForMinutes: data.gatewayMode===COMPANY_NOTIFICATIONS_GATEWAY_STATES.ON?data.gatewayOfflineForMinutes:0,
        smartLockBatteryLevel: data.batteryMode===COMPANY_NOTIFICATIONS_BATTERY_STATES.ON?(
          data.smartLockBatteryLevelSelected===COMPANY_NOTIFICATIONS_BATTERY_LEVELS.L25?25:
          data.smartLockBatteryLevelSelected===COMPANY_NOTIFICATIONS_BATTERY_LEVELS.L50?50:
          75
        ):0,
        atlasOffline: data.atlasMode===COMPANY_NOTIFICATIONS_ATLAS_STATES.ON?true:false,
        sameNotificationAfterHours: data.repeatMode===COMPANY_NOTIFICATIONS_REPEAT_STATES.ON?data.sameNotificationAfterHours:0,
      };
    case RESOURCE: {
      const timeIntervalFrom =
        (moment(data.timeIntervalFrom).hours() * 3600) + (moment(data.timeIntervalFrom).minutes() * 60) + moment(data.timeIntervalFrom).seconds();
      const timeIntervalTo =
        (moment(data.timeIntervalTo).hours() * 3600) + (moment(data.timeIntervalTo).minutes() * 60) + moment(data.timeIntervalTo).seconds();

      let guestTags = data.resourceUserTags;
      if (guestTags && _.isArray(guestTags) && guestTags[0] && _.isObject(guestTags[0])) guestTags = _.map(data.resourceUserTags, val => val.id);

      let lockTags = data.resourceSmartLockTags;
      if (lockTags && _.isArray(lockTags) && lockTags[0] && _.isObject(lockTags[0])) lockTags = _.map(data.resourceSmartLockTags, val => val.id);

      const linkedResourcesIds = _.map(data.dependencies, dependency => dependency.destination.id);

      const workspace = _.isObject(data.workspace) && data.workspace.value ? data.workspace.value : (data.workspace || '');
      return {
        id: data.id,
        name: data.name,
        description: data.description && data.description.length ? data.description : ' ', // WORKAROUND FOR NULLABILITY OF DESCRIPTION
        resourceSmartLockTags: lockTags,
        resourceUserTags: guestTags,
        imageUrl: data.isCustomMedia ? '' : data.imageUrl,
        lockTagMatchingMode: data.lockTagMatchingMode,
        userTagMatchingMode: data.userTagMatchingMode,
        freeBeforeMinutes: data.freeBeforeMinutes != null ? parseFloat(data.freeBeforeMinutes) : 5,
        freeAfterMinutes: data.freeAfterMinutes != null ? parseFloat(data.freeAfterMinutes) : 5,
        checkInWindowMinutes: data.checkInWindowMinutes != null ? parseFloat(data.checkInWindowMinutes) : 15,
        checkInWindowBeforeMinutes: data.checkInWindowBeforeMinutes != null ? parseFloat(data.checkInWindowBeforeMinutes) : 15,
        locksOpenValidAfterMinutes: data.locksOpenValidAfterMinutes != null ? parseFloat(data.locksOpenValidAfterMinutes) : 5,
        maximumReservationTimeMinutes: data.maximumReservationTimeHours != null ? parseFloat(data.maximumReservationTimeHours * 60) : 60,
        deleteByUserWithPenaltyBeforeMinutes: data.deleteByUserWithPenaltyBeforeMinutes != null ? parseFloat(data.deleteByUserWithPenaltyBeforeMinutes) : 0,
        checkInSmartLockId: data.checkInSmartLockId,
        confirmationWindowMinutes: data.confirmationWindowMinutes,
        linkedResourcesIds,
        workspace,
        customMedia: data.customMedia,
        dateInterval: {
          from: moment(data.credentialTimeframe.startDate).startOf('day').valueOf(),
          to: moment(data.credentialTimeframe.endDate).endOf('day').valueOf(),
        },
        timeInterval: {
          from: timeIntervalFrom,
          to: timeIntervalTo,
        },
        daysOfTheWeek: data.daysOfTheWeek,
        type: data.type,
        email: data.email,
        automaticCheckIn: data.automaticCheckIn,
      };
    }

    case RESOURCE_FORM_INIT: {
      return {
        timeIntervalFrom: moment().startOf('day').valueOf(),
        timeIntervalTo: moment().endOf('day').valueOf(),
        credentialTimeframe: {
          startDate: moment().valueOf(),
          endDate: moment().add('years', 5).valueOf(),
        },
        checkInWindowMinutes: 15,
        deleteByUserWithPenaltyBeforeMinutes: 0,
        checkInWindowBeforeMinutes: 15,
        locksOpenValidAfterMinutes: 15,
        daysOfTheWeek: [1, 2, 3, 4, 5, 6, 7],
        maximumReservationTimeHours: 4,
        freeAfterMinutes: 0,
        freeBeforeMinutes: 0,
        lockTagMatchingMode: MATCH_TAG_MODE.AT_LEAST_ONE_TAG,
        userTagMatchingMode: MATCH_TAG_MODE.AT_LEAST_ONE_TAG,
        automaticCheckIn: false,
      };
    }
    case RESOURCE_FORM_EDIT_INIT: {
      const timeIntervalFrom = moment().startOf('day').add(data.timeInterval.from, 'seconds').valueOf();
      const timeIntervalTo = moment().startOf('day').add(data.timeInterval.to, 'seconds').valueOf();
      const linkedResourcesIds = _.map(data.dependencies, dependency => dependency.destination.id);
      const resourceAccessoryIds = _.map(data.resourceAccessories, accessory => accessory.id);
      const isCustomMedia = data.customMedia;
      return {
        ...data,
        timeIntervalFrom,
        timeIntervalTo,
        linkedResourcesIds,
        resourceAccessoryIds,
        isCustomMedia,
        imageUrl: data.media ? data.media: data.imageUrl,
        maximumReservationTimeHours: data.maximumReservationTimeMinutes / 60,
        lockTagMatchingMode: MATCH_TAG_MODE.AT_LEAST_ONE_TAG,
        automaticCheckIn: data.automaticCheckIn,
        credentialTimeframe: {
          startDate: data.dateInterval.from,
          endDate: data.dateInterval.to,
        },
      };
    }
    case ACCESS_PROFILE: {
      const timeIntervalFrom =
        (moment(data.timeIntervalFrom).hours() * 3600) + (moment(data.timeIntervalFrom).minutes() * 60) + moment(data.timeIntervalFrom).seconds();
      const timeIntervalTo =
        (moment(data.timeIntervalTo).hours() * 3600) + (moment(data.timeIntervalTo).minutes() * 60) + moment(data.timeIntervalTo).seconds();
      const dateIntervalTimeFrom = moment(data.credentialTimeframe.startDate)
        .hour(moment(data.dateIntervalTimeFrom).hours())
        .minute(moment(data.dateIntervalTimeFrom).minutes()).startOf('minute').valueOf();
      const dateIntervalTimeTo = moment(data.credentialTimeframe.endDate)
        .hour(moment(data.dateIntervalTimeTo).hours())
        .minute(moment(data.dateIntervalTimeTo).minutes()).startOf('minute').valueOf();
      let lockTags = data.lockTags;
      if (!_.isArray(lockTags)) {
        lockTags = [lockTags];
      }
      return {
        id: data.id,
        name: data.name,
        lockTagIds: _.map(lockTags, val => val.id),
        lockTagMatchingMode: data.lockTagMatchingMode,
        dateInterval: {
          from: dateIntervalTimeFrom,
          to: dateIntervalTimeTo,
        },
        timeInterval: {
          from: timeIntervalFrom,
          to: timeIntervalTo,
        },
        daysOfTheWeek: data.daysOfTheWeek,
        additionalTimeRange: data.additionalTimeRange,
      };
    }
    
    case TICKET: {
      return {
        ...data,
      };
    }
    
    case TICKET_CATEGORY: {
      return {
        ...data,
      };
    }
    
    case AREA: {
      return {
        ...data,
      };
    }
    
    case AREA_OCCUPANCY: {
      return {
        ...data,
      };
    }
    
    case AREA_OCCUPANCY_HOUR: {
      return {
        ...data,
      };
    }

    default: return {};
  }
}

export function resourceDataFormatter(DTO) {
  const base = [
    { label: 'Mo', value: false },
    { label: 'Tu', value: false },
    { label: 'We', value: false },
    { label: 'Th', value: false },
    { label: 'Fr', value: false },
    { label: 'Sa', value: false },
    { label: 'Su', value: false },
  ];
  const checkFields = ['maxReservationLength', 'checkInLimit', 'lockDownPeriod'];
  let daysOfTheWeek;
  if (_.size(DTO.daysOfTheWeek) > 0) {
    daysOfTheWeek = _.map(base, (option, index) => {
      if (_.includes(DTO.daysOfTheWeek, index)) {
        return { label: option.label, value: true };
      } else {
        return { label: option.label, value: false };
      }
    });
  } else {
    daysOfTheWeek = base;
  }
  const data = _.set(DTO, 'daysOfTheWeek', daysOfTheWeek);
  _.map(checkFields, el => {
    if (!_.isArray(data[el])) _.set(data, el, []);
  });
  return data;
}
