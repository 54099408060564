import { IconButton } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import { Form } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import { Field, reduxForm, submit } from 'redux-form';
import { PERMISSION_ENTITIES, PERMISSIONS, RED } from '../../_config/consts.js';
import AbilityProvider from '../../permissionsUtils/AbilityProvider.js';
import CalendarStarIconCustom from '../CustomIcons/CalendarStarIconCustom.jsx';
import DeleteOutlineIconCustom from '../CustomIcons/DeleteOutlineIconCustom.jsx';
import ReloadAlertIconCustom from '../CustomIcons/ReloadAlertIconCustom.jsx';
import ListLoadingView from '../ListLoadingView.jsx';
import MDButton from '../MDButton/MDButton.jsx';
import Permission from '../Permission/Permission.jsx';
import FormCardContainer from './Elements/FormCardContainer.jsx';
import CustomField from './Fields/CustomField.jsx';
import MDCheckBoxField from './Fields/MDCheckBox/MDCheckBoxField.js';


const validate = (values) => {
  const errors = {};
  if (!values.holidayTimeFrame) {
    errors.holidayTimeFrame = 'required';
  }
  return errors;
};

@reduxForm({ form: 'CompanyHolidaysForm', validate })
@connect(state => ({ form: state.form.CompanyHolidaysForm, user: state.user, platformUsers: state.platformUsers}))
class CompanyHolidaysForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usersOptions: [],
      userSelected: null,
      loading: false,
    };
  }


  render() {
    const { formStyle, holidays, dispatch, onNewHoliday, onRemoveHoliday } = this.props;
    return (
      <Form style={formStyle} autoComplete="off">
        <FormCardContainer
          title="holidaysTitle"
          subtitle="holidaysDescription"
          icon={<CalendarStarIconCustom style={{ color: '#3f3f3f', width: 20 }} />}
        >
          <div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div style={{ marginTop: 20 }}>
                <Entity
                  componentClass={Field}
                  title={<Entity entity="holidayTimeFrame" />}
                  name="holidayTimeFrame"
                  containerStyle={{ paddingLeft: 0 }}
                  component={CustomField}
                  type="airbnbDateRange"
                  minimumNights={0}
                />
                <Field
                  name="fixed"
                  label={<Entity entity="holidayRecurrent" />}
                  titleStyle={{ fontSize: 16, fontWeight: 'bold', color: '#3f3f3f' }}
                  containerstyle={{ marginTop: 5, marginLeft: 7, marginTop: -10 }}
                  component={MDCheckBoxField}
                />
              </div>
              <Permission do={[PERMISSIONS.UPDATE]} on={PERMISSION_ENTITIES.COMPANY} abilityHelper={AbilityProvider.getAbilityHelper()}>
                <MDButton
                  title={<Entity entity="addGuestToTag" />}
                  style={{ borderRadius: 5, marginTop: 5 }}
                  containerStyle={{ marginTop: 5 }}
                  onClick={() => dispatch(submit('CompanyHolidaysForm'))}
                />
              </Permission>
            </div>
            {!_.isEmpty(holidays) ? (
              <div style={{ padding: 5, backgroundColor: '#f6f6f6', borderRadius: 10, marginTop: 20 }}>
                <div style={{ maxHeight: 400, padding: 5, overflowY: 'scroll' }}>
                  <InfiniteScroll
                    pageStart={0}
                    useWindow={false}
                    initialLoad={false}
                    loadMore={null}
                    hasMore={false}
                    loader={<ListLoadingView containerStyle={{ backgroundColor: 'transparent' }} />}
                  >
                    <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                      {_.map(holidays, (holiday, index) => {
                        const isMultiDate = holiday.duration > 1;
                        const startDate = moment({
                          year: holiday.year,
                          month: holiday.month - 1,
                          day: holiday.day
                        });
                        const endDate = moment(startDate).add(holiday.duration - 1, 'days');
                        const format = holiday.fixed ? 'DD MMMM' : 'DD MMMM YYYY';
                        return (
                          <div key={holiday}>
                            <div
                              style={{ backgroundColor: index % 2 === 0 ? 'white' : 'ghostwhite', borderBottom: '1px solid', borderTop: '1px solid', alignItems: 'center', justifyContent: 'space-between', display: 'flex', padding: 5, borderColor: '#f0f0f0' }}
                              key={holiday}
                            >
                              <div>
                                <h5 style={{ color: '#3f3f3f', marginLeft: 10, fontWeight: 'bold', marginBottom: 5, marginTop: 10, fontSize: 15 }}>{moment(startDate).format(format)} {isMultiDate ? `- ${moment(endDate).format(format)}` : null}</h5>
                                  <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 10, marginBottom: 5, alignItems: 'center' }}>
                                    {holiday.fixed ? (
                                    <>
                                      <Refresh style={{ fontSize: 18, marginRight: 5 }} />
                                      <h5 style={{ color: '#3f3f3f', margin: 0, fontSize: 15 }}>
                                        <Entity key={holiday.fixed} entity="recurrent" />
                                      </h5>
                                    </>
                                    ) : (
                                      <>
                                        <ReloadAlertIconCustom style={{ width: 18, marginRight: 5 }} />
                                        <h5 style={{ color: '#3f3f3f', margin: 0, fontSize: 15 }}>
                                          <Entity key={holiday.fixed} entity="notRecurrent" />
                                        </h5>
                                      </>
                                    )}
                                  </div>
                              </div>
                              <Permission do={[PERMISSIONS.UPDATE]} on={PERMISSION_ENTITIES.COMPANY} abilityHelper={AbilityProvider.getAbilityHelper()}>
                                <IconButton
                                  style={{ color: RED }}
                                  onClick={() => onRemoveHoliday(holiday)}
                                >
                                  <DeleteOutlineIconCustom style={{ width: 20 }} />
                                </IconButton>
                              </Permission>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </InfiniteScroll>
                </div>
              </div>
            ) : (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 20 }}>
              <CalendarStarIconCustom style={{ color: '#3f3f3f', margin: 'auto', width: 35 }} />
              <h4 style={{ color: '#3f3f3f', textAlign: 'center' }}>
                <Entity entity="noHolidayFound" />
              </h4>
            </div>
          )}
          </div>
        </FormCardContainer>
      </Form>
    );
  }
} 

export default CompanyHolidaysForm;
