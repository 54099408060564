// @ts-nocheck
import { Row } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { change, Field } from 'redux-form';
import MDRadioButtonView from '../Elements/MDRadioButtonsGroup/MDRadioButtonView.js';
import CustomField from '../Fields/CustomField.jsx';
import MDCheckBoxGroupField from '../Fields/MDCheckBox/MDCheckBoxGroupField.js';
import MDCheckBoxField from '../Fields/MDCheckBox/MDCheckBoxField.js';

const timeOptions = [
  {
    value: 1,
    label: <Entity entity="twentifourhours" />,
  },
  {
    value: 2,
    label: <Entity entity="moringTime" />,
  },
  {
    value: 3,
    label: <Entity entity="afternoonTime" />,
  },
  {
    value: 4,
    label: <Entity entity="moringAndAfternoon" />,
  },
];

@connect((state) => ({ companyConfigurations: state.user.companyConfigurations }))
export default class AdditionalTimeRangeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeOptionSelected: 0,
    };
  }

  onDateCheckboxChange(value) {
    const { dispatch, timeRangeIndex, timeRangesFieldName, formName } = this.props;
    this.setState({ timeOptionSelected: value });
    switch (value) {
      case 1: {
        dispatch(change(formName, `${timeRangesFieldName}[${timeRangeIndex}].timeIntervalFrom`, moment().startOf('day').valueOf()));
        dispatch(change(formName, `${timeRangesFieldName}[${timeRangeIndex}].timeIntervalTo`, moment().endOf('day').valueOf()));
        break;
      }
      case 2: {
        dispatch(change(formName, `${timeRangesFieldName}[${timeRangeIndex}].timeIntervalFrom`, moment().startOf('day').set('hours', 9).valueOf()));
        dispatch(change(formName, `${timeRangesFieldName}[${timeRangeIndex}].timeIntervalTo`, moment().startOf('day').set('hours', 13).valueOf()));
        break;
      }
      case 3: {
        dispatch(change(formName, `${timeRangesFieldName}[${timeRangeIndex}].timeIntervalFrom`, moment().startOf('day').set('hours', 14).valueOf()));
        dispatch(change(formName, `${timeRangesFieldName}[${timeRangeIndex}].timeIntervalTo`, moment().startOf('day').set('hours', 18).valueOf()));
        break;
      }
      case 4: {
        dispatch(change(formName, `${timeRangesFieldName}[${timeRangeIndex}].timeIntervalFrom`, moment().startOf('day').set('hours', 9).valueOf()));
        dispatch(change(formName, `${timeRangesFieldName}[${timeRangeIndex}].timeIntervalTo`, moment().startOf('day').set('hours', 18).valueOf()));
        break;
      }
      default: {
        dispatch(change(formName, `${timeRangesFieldName}[${timeRangeIndex}].timeIntervalFrom`, moment().startOf('day').valueOf()));
        dispatch(change(formName, `${timeRangesFieldName}[${timeRangeIndex}].timeIntervalTo`, moment().endOf('day').valueOf()));
        break;
      }
    }
  }

  render() {
    const { canEdit, form, areDeviceDatesLocked, companyConfigurations, availableLockTags, locksFilteredByTags, timeRange, is12HoursFormat } = this.props;
    const { timeOptionSelected } = this.state;
    const daysOptions = _.map(moment.weekdays(true), weekday => ({
      label: weekday,
      value: moment().isoWeekday(weekday).isoWeekday(),
    }));

    return (
      <div style={{ paddingLeft: 15 }}>
        <Row>
          <input style={{ display: 'none' }} type="username" name="username" />
          <div style={{ paddingLeft: 30, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ marginLeft: 8, marginRight: 60 }}>
              <Entity
                componentClass={Field}
                title={<Entity entity="timeIntervalFrom" />}
                name={`${timeRange}.timeIntervalFrom`}
                component={CustomField}
                type="antdTimePicker"
                is12HoursFormat={is12HoursFormat}
                placeholder="Select a time"
                disabled={!canEdit || areDeviceDatesLocked}
              />
            </div>
            <Entity
              componentClass={Field}
              title={<Entity entity="timeIntervalTo" />}
              name={`${timeRange}.timeIntervalTo`}
              component={CustomField}
              is12HoursFormat={is12HoursFormat}
              type="antdTimePicker"
              placeholder="Select a time"
              disabled={!canEdit || areDeviceDatesLocked}
            />
            <div style={{ marginLeft: 30, marginTop: 20 }}>
              {_.map(timeOptions, timeOption => (
                <MDRadioButtonView
                  key={timeOption.value}
                  checked={timeOptionSelected === timeOption.value}
                  value={(timeOption.value).toString()}
                  label={timeOption.label}
                  disabled={!canEdit || areDeviceDatesLocked}
                  handleChange={value => this.onDateCheckboxChange(timeOption.value)}
                />
              ))}
            </div>
          </div>
        </Row>
        <Field
          title={<Entity entity="daysOfTheWeek" />}
          name={`${timeRange}.daysOfTheWeek`}
          component={MDCheckBoxGroupField}
          containerstyle={{ paddingLeft: 15, marginTop: 10 }}
          horizontal
          options={daysOptions}
          disabled={!canEdit || areDeviceDatesLocked}
        />
        {companyConfigurations.holidaysActive ? (
          <div style={{ paddingLeft: 25, marginTop: 10 }}>
            <Field
              name={`${timeRange}.holidays`}
              label={<Entity entity="includeHolidays" />}
              titleStyle={{ fontSize: 16, fontWeight: 'bold', color: '#3f3f3f' }}
              containerstyle={{ marginTop: 0 }}
              disabled={!canEdit}
              component={MDCheckBoxField}
            />
            <h4 style={{ marginTop: -10, lineHeight: 1.5, marginLeft: 35, fontSize: 15, color: '#3f3f3f', fontWeight: 'light' }}><Entity entity="includeHolidaysDescription" /></h4>
          </div>
        ) : null}
      </div>
    );
  }
}
