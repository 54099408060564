import IconButton from '@material-ui/core/IconButton';
import Cancel from '@material-ui/icons/Cancel';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import React from 'react';
import { connect } from 'react-redux';
import * as SettingsActions from '../../redux/actions/setting.actions';
import AssistanceSettingsOperationalSection from './OperationalSettings/AssistanceSettingsOperationalSection.jsx';
import CompanySettingsOperationalSection from './OperationalSettings/CompanySettingsOperationalSection.jsx';
import LicenseSettingsOperationalSection from './OperationalSettings/LicenseSettingsOperationalSection.jsx';
import PrivacyAndTermsOperationalSection from './OperationalSettings/PrivacyAndTermsOperationalSection.jsx';
import ThemeSettingsOperationalSection from './OperationalSettings/ThemeSettingsOperationalSection.jsx';
import WalletManagementPage from '../../routes/CloudCredits/WalletManagementPage.jsx';
import CustomFieldsPage from '../../routes/CustomFields/CustomFieldsPage.jsx';
import NexudusSettingsOperationalSection from './OperationalSettings/NexudusSettingsOperationalSection.jsx';
import EagleEyeSettingsOperationalSection from './OperationalSettings/EagleEyeSettingsOperationalSection.jsx';
import CobotSettingsOperationalSection from './OperationalSettings/CobotSettingsOperationalSection.jsx';
import OfficeRndSettingsOperationalSection from './OperationalSettings/OfficeRndSettingsOperationalSection.jsx';
import ChainelsSettingsOperationalSettings from './OperationalSettings/ChainelsSettingsOperationalSettings.jsx';
import AndcardsSettingsOperationalSections from './OperationalSettings/AndcardsSettingsOperationalSections.jsx';
import DevicesDefaultsSettingsOperationalSection from './OperationalSettings/DevicesDefaultsSettingsOperationalSection.jsx';
import Office365SettingsOperationalSection from './OperationalSettings/Office365SettingsOperationalSection.jsx';
import CloudBedsOperationalSections from './OperationalSettings/CloudBedsOperationalSections.jsx';
import MewsSettingsOperationalSection from './OperationalSettings/MewsSettingsOperationalSection.jsx';
import OptixSettingsOperationalSection from './OperationalSettings/OptixSettingsOperationalSection.jsx';
import SystemSettingsOperationalSection from './OperationalSettings/SystemSettingsOperationalSection.jsx';
import TenupSettingsOperationalSection from './OperationalSettings/TenupSettingsOperationalSection.jsx';
import TicketCategoriesPage from '../../routes/TicketCategoriesPage.jsx';
import ZapfloorSettingsOperationalSection from './OperationalSettings/ZapfloorSettingsOperationalSection.jsx';
import OctorateSettingsOperationalSection from './OperationalSettings/OctorateSettingsOperationalSection.jsx';
import CompanyNotificationsOperationalSection from './OperationalSettings/CompanyNotificationsOperationalSection.jsx';
import HolidaysOperationalSection from './OperationalSettings/HolidaysOperationalSection.jsx';

const settingsComponents = {
  theme: ThemeSettingsOperationalSection,
  company: CompanySettingsOperationalSection,
  license: LicenseSettingsOperationalSection,
  assistance: AssistanceSettingsOperationalSection,
  privacy: PrivacyAndTermsOperationalSection,
  wallet: WalletManagementPage,
  customFields: CustomFieldsPage,
  ticketCategories: TicketCategoriesPage,
  nexudus: NexudusSettingsOperationalSection,
  eagleEye: EagleEyeSettingsOperationalSection,
  cobot: CobotSettingsOperationalSection,
  officeRnd: OfficeRndSettingsOperationalSection,
  chainels: ChainelsSettingsOperationalSettings,
  andcards: AndcardsSettingsOperationalSections,
  office365: Office365SettingsOperationalSection,
  cloudbeds: CloudBedsOperationalSections,
  mews: MewsSettingsOperationalSection,
  optix: OptixSettingsOperationalSection,
  devicesDefaults: DevicesDefaultsSettingsOperationalSection,
  systemSettings: SystemSettingsOperationalSection,
  tenup: TenupSettingsOperationalSection,
  zapfloor: ZapfloorSettingsOperationalSection,
  octorate: OctorateSettingsOperationalSection,
  companyNotifications: CompanyNotificationsOperationalSection,
  holidaysSettings: HolidaysOperationalSection,
};

@connect(state => ({ setting: state.settings.selectedSetting }))
class SettingsOperationalSection extends React.Component {

  componentDidMount() {
    document.addEventListener('keydown', e => this.onKeyPressed(e), false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', e => this.onKeyPressed(e), false);
  }

  onKeyPressed(e) {
    if (e.key === 'Escape') {
      const { onClose } = this.props;
      this.closeOperationalSection();
    }
  }

  closeOperationalSection() {
    const { dispatch } = this.props;
    dispatch(SettingsActions.setOperationalMode(false));
    dispatch(SettingsActions.selectSetting({}));
  }

  render() {
    const { setting, themeName } = this.props;
    if (!setting.name) {
      return null;
    }

    const Component = settingsComponents[setting.name];

    return (
      Component ?
        <div className="operational-view">
          <div className={`operational-view-title-container bg-${themeName}-theme`}>
            <IconButton onClick={() => this.closeOperationalSection()} style={{ marginLeft: 10 }}>
              <Cancel style={{ color: 'white', fontSize: 30 }} />
            </IconButton>
            <h3 className="operational-view-title">
              <Entity key={setting.name} entity="settingOperationalTitle" data={{ name: setting.name }} />
            </h3>
          </div>
          <div className="operational-view-container" style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Component setting={setting} closeOperationalSection={() => this.closeOperationalSection()} />
          </div>
        </div> : null
    );
  }
} 

export default SettingsOperationalSection;
