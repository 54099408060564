// @ts-nocheck
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Autorenew';
import DeleteIcon from '@material-ui/icons/RemoveCircleOutline';
import RoleIcon from '@material-ui/icons/Security';
import { Entity, ctx as L20NContext } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { GREEN, PERMISSIONS, PERMISSION_ENTITIES, SOFIA_SUPPORT_MANAGERS_EMAILS } from '../../_config/consts';
import { getColorFromThemeName } from '../../_config/utils';
import AbilityProvider from '../../permissionsUtils/AbilityProvider';
import * as GuestActions from '../../redux/actions/guest.actions';
import * as RolesActions from '../../redux/actions/roles.actions';
import CompanyReportIconCustom from '../CustomIcons/CompanyReportIconCustom.jsx';
import SortIconCustom from '../CustomIcons/SortIconCustom.jsx';
import TwoFactorIconCustom from '../CustomIcons/TwoFactorIconCustom.jsx';
import UnsortIconCustom from '../CustomIcons/UnsortIconCustom.jsx';
import UserCheckIconCustom from '../CustomIcons/UserCheckIconCustom.jsx';
import UserDisableIconCustom from '../CustomIcons/UserDisableIconCustom.jsx';
import UsersIconCustom from '../CustomIcons/UsersIconCustom.jsx';
import EmptyView from '../EmptyView.jsx';
import ListLoadingView from '../ListLoadingView.jsx';
import MDTablePaginationActions from '../MDTable/MDTablePaginationActions.jsx';

import * as PlatformUsersActions from '../../redux/actions/platformUsers.actions';
import SortAscendingIconCustom from '../CustomIcons/SortAscendingIconCustom.jsx';
import SortDescendingIconCustom from '../CustomIcons/SortDescendingIconCustom.jsx';

const styles = theme => ({
  table: {
    overflow: 'scroll',
  },
  tableColumnText: {
    fontWeight: 'bold',
    color: '#3f3f3f',
  },
  tableColumnHeader: {
    display: 'flex',
    flexDirection: 'row',
  },
  tableColumnHeaderText: {
    fontWeight: 'bold',
    color: '#3f3f3f',
  },
  tableColumnHeaderArrow: {
    padding: 10,
    marginLeft: 10,
  },
  tableColumnHeaderArrowIcon: {
    fontSize: 20,
    width: 20,
  },
  tableColumnHeaderSortIcon: {
    width: 24,
  },
  disabledInput: {
    display: 'none',
  },
  guestTag: {
    padding: 3,
  },
  rowSelected: {
    backgroundColor: 'aliceblue !important',
    color: 'aliceblue !important',
  },
  rowHovered: {
    cursor: 'pointer',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
  button: {
    color: '#E53935',
    fontSize: 12,
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  guestRowContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowWithAction: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowActionButton: {
    width: 45,
    height: 45,
  },
  rowActionButtonIcon: {
    fontSize: 20,
    color: '#E53935',
  },
  paginationText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'white',
  },
});

@connect((state) => ({ viewLoading: state.utils.viewLoading, userData: state.user.data, companyConfigurations: state.user.companyConfigurations, platformUsers: state.platformUsers, themeName: state.settings.items.theme.data.themeName }))
class PlatformUsersTable extends React.Component {
  
  async handleOrderChange(column) {
    const { onOrderChanged, dispatch } = this.props;
    const { platformUsers: { data: { sorting: {orderBy, orderDir} } } } = this.props;
    const orderDir_new = orderBy===column?(orderDir==='ASC'?'DESC':'ASC'):'ASC';
    await dispatch(PlatformUsersActions.setOrder(column, orderDir_new));
    onOrderChanged()
  };

  async onResetOrder() {
    const { onOrderChanged, dispatch } = this.props;
    await dispatch(PlatformUsersActions.setOrder(undefined, undefined));
    onOrderChanged()
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(GuestActions.setOperationalMode(false));
  }

  render() {
    const {
      classes,
      platformUsers: { data: { content: platformUserData, pagination: { number: currentPage, size, totalPages, totalElements }, filters, sorting: {orderBy, orderDir} } },
      platformUsers: { selectedUser },
      userData,
      onDisableUserRequest,
      onResetUserPassword,
      onDeleteUserRequest,
      onRefreshUsers,
      onFetchUserOnPage,
      onNewPlatformUser,
      viewLoading,
      onSelectUser,
      themeName
    } = this.props;
    const canCreateGuest = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.CREATE], PERMISSION_ENTITIES.USER);
    const canDeleteUser = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.DELETE], PERMISSION_ENTITIES.USER);
    const canEditUser = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.UPDATE], PERMISSION_ENTITIES.USER);
    const headerColor = getColorFromThemeName(themeName);
    return (
      <div className="list-view details-section" style={{ top: 190, paddingBottom: 140, width: '87%' }}>
        <div>
          {!_.isEmpty(platformUserData) ?
            <Card style={{ overflow: 'scroll' }}>
              <Table size="small" className={classes.table}>
                <TableHead>
                  <TableRow style={{ backgroundColor: headerColor }}>
                    <TableCell colSpan={0}>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <h3 className={classes.tableColumnText} style={{ color: 'white', marginTop: 15, marginBottom: 15 }}><Entity entity="platformUsers" /></h3>
                        <IconButton onClick={() => onRefreshUsers()} style={{ marginLeft: 10, marginTop: 5 }}>
                          <RefreshIcon style={{ color: 'white', fontSize: 25 }} />
                        </IconButton>
                        {orderBy && 
                          <IconButton onClick={() => this.onResetOrder()} style={{ marginLeft: 5, marginTop: 5 }}>
                            <UnsortIconCustom style={{ color: 'white', width: 25 }} />
                          </IconButton>
                        }
                      </div>
                    </TableCell>
                    <TableCell>
                    </TableCell>
                    <TablePagination
                      colSpan={0}
                      count={!_.isUndefined(totalElements) ? totalElements : 0}
                      rowsPerPage={!_.isUndefined(size) ? size : 0}
                      page={!_.isUndefined(currentPage) ? currentPage : 0}
                      labelRowsPerPage=" "
                      classes={{
                        input: classes.disabledInput,
                      }}
                      labelDisplayedRows={({ from, to, count }) => (
                        <h4 className={classes.paginationText}>
                          <Entity
                            key={`${from}-${totalPages}-${count}`}
                            entity="userTablePagination"
                            data={{
                              currentPage: !_.isUndefined(currentPage) ? currentPage + 1 : 0,
                              totalPages: !_.isUndefined(totalPages) ? totalPages : 0,
                              count: !_.isUndefined(count) ? count : 0,
                            }}
                          />
                        </h4>
                      )}
                      onChangePage={(event, page) => onFetchUserOnPage(page)}
                      ActionsComponent={() => (
                        <MDTablePaginationActions
                          count={totalElements}
                          iconColor="white"
                          page={currentPage}
                          lastPage={totalPages - 1}
                          rowsPerPage={size}
                          onChangePage={(event, page) => onFetchUserOnPage(page)}
                        />
                      )}
                    />
                    </TableRow>
                  <TableRow>
                    <TableCell>
                      <div className={classes.tableColumnHeader}>
                        <h4 className={classes.tableColumnHeaderText}><Entity entity="user" /></h4>
                        {orderBy === 'email'?(
                          <IconButton onClick={() => this.handleOrderChange('email')} style={{opacity: 1}} className={classes.tableColumnHeaderArrow}>
                            {orderDir==='ASC'||orderBy!=='email'?<SortDescendingIconCustom className={classes.tableColumnHeaderArrowIcon}/> : <SortAscendingIconCustom className={classes.tableColumnHeaderArrowIcon}/>}
                          </IconButton>
                        ):(
                          <IconButton onClick={() => this.handleOrderChange('email')} style={{opacity: 0.6}} className={classes.tableColumnHeaderArrow}>
                            {<SortIconCustom className={classes.tableColumnHeaderSortIcon}/>}
                          </IconButton>
                        )}
                      </div>
                    </TableCell>
                    <TableCell><h4 className={classes.tableColumnHeaderText}><Entity entity="user" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnHeaderText}><Entity entity="userRole" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnHeaderText}><Entity entity="userState" /></h4></TableCell>
                    <TableCell><h4 className={classes.tableColumnHeaderText}><Entity entity="actions" /></h4></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.map(platformUserData, (user) => {
                    const statusColor = !user.enabled ? '#E53935' : '#43A047';
                    const isSelfEditing = userData && userData.id && user && user.id && (userData.id === user.id);
                    const isSupportManager = user && user.email && _.findIndex(SOFIA_SUPPORT_MANAGERS_EMAILS, email => email === user.email) !== -1;
                    return (
                      <TableRow
                        key={user.id}
                        className={classes.row}
                        hover
                        classes={{
                          selected: classes.rowSelected,
                          hover: classes.rowHovered,
                        }}
                        onClick={event => onSelectUser(user)}
                        selected={selectedUser && selectedUser.id === user.id}
                      >
                        <TableCell>
                          <div className={classes.guestRowContainer}>
                            <div>
                              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10, marginBottom: 5 }}>
                                <h5 style={{ fontWeight: 'bold', margin: 0, marginRight: 10, fontSize: 16 }}>{`${user.firstname} ${user.lastname}`}</h5>
                                {user.using2FA ? <p style={{ margin: 0, alignSelf: 'flex-start' }} data-tip={L20NContext.getSync('userHasTwoFactor')}><TwoFactorIconCustom style={{ color: GREEN, marginTop: 0, width: 20, marginRight: 5 }} /></p> : null}
                                {user.companyNotificationsEnabled ? <p style={{ margin: 0, alignSelf: 'flex-start' }} data-tip={L20NContext.getSync('userCompanyNotificationsEnabled')}><CompanyReportIconCustom style={{ color: GREEN, marginTop: 0, width: 20 }} /></p> : null}
                              </div>
                              <h5 style={{ marginTop: 10 }}>{user.email}</h5>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          {_.map(user.roles, (role) => {
                            const formattedRole = RolesActions.formatRole(role);
                            const isRoleGuest = role && role.id === 5;
                            return (
                              <div key={role.id} style={{ display: 'flex', marginRight: 10, marginTop: 5 }}>
                                {!isRoleGuest ? <RoleIcon style={{ color: '#3f3f3f', marginTop: 3, fontSize: 20 }} /> : <UsersIconCustom style={{ color: '#3f3f3f', marginTop: 3, width: 20 }} />}
                                <h5 className="card-description" style={{ margin: 5, fontWeight: 'bold', marginTop: 6 }}>{formattedRole.label}</h5>
                              </div>
                            );
                          })}
                        </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.rowWithAction}>
                            <div style={{ backgroundColor: statusColor, width: 8, height: 8, borderRadius: 50, marginRight: 5 }} />
                            <h5>
                              <Entity
                                key={`${user.enabled}`}
                                style={{ fontWeight: 'bold', textTransform: 'uppercase', color: statusColor, textAlign: 'center', fontSize: 12, margin: 0 }}
                                entity={!user.enabled ? 'userDisabled' : 'userEnabled'}
                              />
                            </h5>
                            {canEditUser && user.enabled && !isSelfEditing && !isSupportManager ? (
                              <p style={{ margin: 0, alignSelf: 'flex-start' }} data-tip={L20NContext.getSync('deactivateUser')}>
                                <IconButton
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    onDisableUserRequest(user);
                                  }}
                                  className={classes.rowActionButton}
                                >
                                  <UserDisableIconCustom className={classes.rowActionButtonIcon} style={{ fontSize: 20, marginLeft: 2 }} />
                                </IconButton>
                              </p>
                            ) : null}
                            {canEditUser && !user.enabled && !isSelfEditing && !isSupportManager ? (
                              <p style={{ margin: 0, alignSelf: 'flex-start' }} data-tip={L20NContext.getSync('activateUser')}>
                                <IconButton
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    onResetUserPassword(user);
                                  }}
                                  className={classes.rowActionButton}
                                >
                                  <UserCheckIconCustom className={classes.rowActionButtonIcon} style={{ color: '#43A047', fontSize: 20, marginLeft: 2 }} />
                                </IconButton>
                              </p>
                            ) : null}
                          </div>
                        </TableCell>
                        <TableCell>
                          {canDeleteUser && !isSelfEditing && !isSupportManager ? (
                            <div className={classes.actionContainer}>
                              <Button
                                onClick={(event) => {
                                  event.stopPropagation();
                                  onDeleteUserRequest(user);
                                }}
                                className={classes.button}
                              >
                                <Entity entity="delete" />
                                <DeleteIcon style={{ fontSize: 15, marginLeft: 5 }} />
                              </Button>
                            </div>
                        ) : null}
                        </TableCell>
                        <ReactTooltip delayShow={200} effect="float" />
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow style={{ backgroundColor: headerColor }}>
                    <TablePagination
                      colSpan={0}
                      count={!_.isUndefined(totalElements) ? totalElements : 0}
                      rowsPerPage={!_.isUndefined(size) ? size : 0}
                      page={!_.isUndefined(currentPage) ? currentPage : 0}
                      labelRowsPerPage=" "
                      classes={{
                        input: classes.disabledInput,
                      }}
                      labelDisplayedRows={({ from, to, count }) => (
                        <h4 className={classes.paginationText}>
                          <Entity
                            key={`${from}-${totalPages}-${count}`}
                            entity="userTablePagination"
                            data={{
                              currentPage: !_.isUndefined(currentPage) ? currentPage + 1 : 0,
                              totalPages: !_.isUndefined(totalPages) ? totalPages : 0,
                              count: !_.isUndefined(count) ? count : 0,
                            }}
                          />
                        </h4>
                      )}
                      onChangePage={(event, page) => onFetchUserOnPage(page)}
                      ActionsComponent={() => (
                        <MDTablePaginationActions
                          count={totalElements}
                          page={currentPage}
                          iconColor="white"
                          lastPage={totalPages - 1}
                          rowsPerPage={size}
                          onChangePage={(event, page) => onFetchUserOnPage(page)}
                        />
                      )}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </Card>
            : (
              <div>
                {!viewLoading ? (
                  <EmptyView
                    title={filters && !filters.search ? 'noUser' : 'noUserFound'}
                    onNewEntity={canCreateGuest ? () => onNewPlatformUser() : null}
                    newEntityTitle="addPlatformUser"
                    iconName="icon-simple-line-icons-user"
                  />) : <ListLoadingView />}
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(PlatformUsersTable);
