// @ts-nocheck
import { Row } from '@sketchpixy/rubix';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import InfoIcon from '@material-ui/icons/HelpOutline';
import AddIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { change, Field, FieldArray } from 'redux-form';
import MDRadioButtonView from '../Elements/MDRadioButtonsGroup/MDRadioButtonView.js';
import CustomField from '../Fields/CustomField.jsx';
import MDCheckBoxGroupField from '../Fields/MDCheckBox/MDCheckBoxGroupField.js';
import TagsSelectorListField from '../Elements/TagsSelectorListField.jsx';
import FormCardContainer from '../Elements/FormCardContainer.jsx';
import AdditionalTimeRangeForm from './AdditionalTimeRangeForm.jsx';
import { Card } from '@material-ui/core';
import { ORANGE, RED, VENDORS_LIST } from '../../../_config/consts.js';
import { WarningOutlined } from '@material-ui/icons';
import MDCheckBoxField from '../Fields/MDCheckBox/MDCheckBoxField.js';


const onRenderTimeRange = (props) => {
  const { fields, areDeviceDatesLocked, formName, canEdit, formData, timeRangesFieldName, is12HoursFormat, onInitializeNewTimeRange } = props;
  const numberOfTimeRanges = _.size(fields);
  return (
    <div>
      {fields && fields.map((timeRange, index) => (
        <div key={`timeRange${index + 1}`} elevation={2} style={{ marginTop: 20}}>
          <Divider />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 10, paddingRight: 10 }}>
            <h4 style={{ fontWeight: 'bold', margin: 10 }}><Entity key={index+1} entity="additionalTimeRange" /></h4>
            <IconButton
              style={{ marginLeft: 10, marginBottom: 5 }}
              onClick={(e) => {
                e.stopPropagation();
                if (!formData.isEditing) {
                  fields.remove(index);
                }
              }}
            >
              <DeleteIcon style={{ color: RED, fontSize: 20 }} />
            </IconButton>
          </div>
          <AdditionalTimeRangeForm
            formName={formName}
            timeRange={timeRange}
            timeRangeIndex={index}
            timeRangesFieldName={timeRangesFieldName}
            areDeviceDatesLocked={areDeviceDatesLocked}
            canEdit={canEdit}
            is12HoursFormat={is12HoursFormat}
          />
        </div>
      ))}
      {numberOfTimeRanges < 1 ? (
        <Card
          style={{ height: 60, marginBottom: 20, marginTop: 20, padding: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          className="card card-rounded"
          onClick={() => {
            fields.push({});
            onInitializeNewTimeRange(numberOfTimeRanges);
          }}
        >
          <AddIcon style={{ color: '#4DB6AC', marginRight: 15, fontSize: 25 }} />
          <h4 style={{ fontWeight: 'bold' }}><Entity entity="newTimeRange" /></h4>
        </Card>
      ) : null}
    </div>
  );
}

const timeOptions = [
  {
    value: 1,
    label: <Entity entity="twentifourhours" />,
  },
  {
    value: 2,
    label: <Entity entity="moringTime" />,
  },
  {
    value: 3,
    label: <Entity entity="afternoonTime" />,
  },
  {
    value: 4,
    label: <Entity entity="moringAndAfternoon" />,
  },
];

@connect((state) => ({ companyConfigurations: state.user.companyConfigurations }))
export default class AdditionalTimeProfileForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeOptionSelected: 0,
    };
  }

  onDateCheckboxChange(value) {
    const { dispatch, timeProfileIndex, formName } = this.props;
    this.setState({ timeOptionSelected: value });
    switch (value) {
      case 1: {
        dispatch(change(formName, `additionalTimeProfiles[${timeProfileIndex}].timeIntervalFrom`, moment().startOf('day').valueOf()));
        dispatch(change(formName, `additionalTimeProfiles[${timeProfileIndex}].timeIntervalTo`, moment().endOf('day').valueOf()));
        break;
      }
      case 2: {
        dispatch(change(formName, `additionalTimeProfiles[${timeProfileIndex}].timeIntervalFrom`, moment().startOf('day').set('hours', 9).valueOf()));
        dispatch(change(formName, `additionalTimeProfiles[${timeProfileIndex}].timeIntervalTo`, moment().startOf('day').set('hours', 13).valueOf()));
        break;
      }
      case 3: {
        dispatch(change(formName, `additionalTimeProfiles[${timeProfileIndex}].timeIntervalFrom`, moment().startOf('day').set('hours', 14).valueOf()));
        dispatch(change(formName, `additionalTimeProfiles[${timeProfileIndex}].timeIntervalTo`, moment().startOf('day').set('hours', 18).valueOf()));
        break;
      }
      case 4: {
        dispatch(change(formName, `additionalTimeProfiles[${timeProfileIndex}].timeIntervalFrom`, moment().startOf('day').set('hours', 9).valueOf()));
        dispatch(change(formName, `additionalTimeProfiles[${timeProfileIndex}].timeIntervalTo`, moment().startOf('day').set('hours', 18).valueOf()));
        break;
      }
      default: {
        dispatch(change(formName, `timeProfiles[${timeProfileIndex}].timeIntervalFrom`, moment().startOf('day').valueOf()));
        dispatch(change(formName, `timeProfiles[${timeProfileIndex}].timeIntervalTo`, moment().endOf('day').valueOf()));
        break;
      }
    }
  }

  render() {
    const { canEdit, form, formData, formName, areSelectedSmartLocksOverlappingError, lockHasErrorCondition, hasSelectedIncompatibleLocks, isArgoMultiTimeProfileActive, onInitializeNewTimeRange, areDeviceDatesLocked, availableLockTags, locksFilteredByTags, timeProfile, is12HoursFormat, onFetchMoreLocks, onLockTagsChange, onLockInputChange, onLockFilterModeChange, companyConfigurations } = this.props;
    const { timeOptionSelected } = this.state;
    const daysOptions = _.map(moment.weekdays(true), weekday => ({
      label: weekday,
      value: moment().isoWeekday(weekday).isoWeekday(),
    }));
    const hasSelectedArgoSmartLocks = isArgoMultiTimeProfileActive ? false : locksFilteredByTags && locksFilteredByTags.data && _.find(locksFilteredByTags.data, smartLock => smartLock.vendor === VENDORS_LIST.ISEO_ARGO);
    return (
      <div style={{ paddingLeft: 15 }}>
        <Row>
          <FormCardContainer
            title="cardLockTitle"
            subtitle="cardTagLockSubtitle"
            hideCard
          >
            <TagsSelectorListField
              name={`${timeProfile}.lockTags`}
              selectorFieldName={`${timeProfile}.lockTagMatchingMode`}
              formName={formName}
              selectedTagsFieldName="selectedLocksTags"
              tags={availableLockTags}
              selectedTags={locksFilteredByTags}
              form={form}
              tagsType="LOCK"
              placeholder="insertLockTagsForCredential"
              listTitle="selectedLocks"
              itemHasWarningCondition={isArgoMultiTimeProfileActive ? false : lock => lock.vendor === VENDORS_LIST.ISEO_ARGO}
              title="lockTags"
              onFetchMoreItems={page => onFetchMoreLocks(page)}
              onTagsChange={values => onLockTagsChange(values)}
              onInputChange={value => onLockInputChange(value)}
              itemHasErrorCondition={lockHasErrorCondition ? (value) => lockHasErrorCondition(value) : null }
              onFilterModeChange={values => onLockFilterModeChange(values)}
              disabled={!canEdit}
            />
          </FormCardContainer>
          {hasSelectedArgoSmartLocks ? (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 25, paddingRight: 20, marginBottom: 20 }}>
              <WarningOutlined style={{ fontSize: 20, color: 'darkorange', marginRight: 10 }} />
              <h5 style={{ margin: 0, color: 'darkorange', lineHeight: 1.2 }}><Entity entity="additionalProfileArgoWarning" /></h5>
            </div>
          ) : null}
          {hasSelectedIncompatibleLocks ? (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 25, paddingRight: 20, marginBottom: 20 }}>
              <WarningOutlined style={{ fontSize: 20, color: 'darkorange', marginRight: 10 }} />
              <h5 style={{ margin: 0, color: 'darkorange', lineHeight: 1.2 }}><Entity entity="selectedLocksRFIDIncompatibleError" /></h5>
            </div>
          ) : null}
          {areSelectedSmartLocksOverlappingError ? (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 25, paddingRight: 20, marginBottom: 20 }}>
              <WarningOutlined style={{ fontSize: 20, color: 'darkorange', marginRight: 10 }} />
              <h5 style={{ margin: 0, color: 'darkorange', lineHeight: 1.2 }}><Entity entity="selectedSmartLocksOverlappingError" /></h5>
            </div>
          ) : null}
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Entity
              componentClass={Field}
              title={<Entity entity="credentialTimeFrame" />}
              name={`${timeProfile}.credentialTimeframe`}
              containerStyle={{ paddingLeft: 30 }}
              component={CustomField}
              type="airbnbDateRange"
              disabled={!canEdit || areDeviceDatesLocked}
              minimumNights={0}
            />
            {companyConfigurations.checkincheckoutActive ?
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{ marginLeft: 8, marginRight: 30, marginLeft: 30 }}>
                  <Entity
                    componentClass={Field}
                    title={<Entity entity="validityTimeStart" />}
                    name={`${timeProfile}.dateIntervalTimeFrom`}
                    component={CustomField}
                    type="antdTimePicker"
                    is12HoursFormat={is12HoursFormat}
                    placeholder="Select a time"
                    disabled={!canEdit || areDeviceDatesLocked}
                  />
                </div>
                <Entity
                  componentClass={Field}
                  title={<Entity entity="validityTimeEnd" />}
                  name={`${timeProfile}.dateIntervalTimeTo`}
                  component={CustomField}
                  is12HoursFormat={is12HoursFormat}
                  type="antdTimePicker"
                  placeholder="Select a time"
                  disabled={!canEdit || areDeviceDatesLocked}
                />
              </div> : null
            }
          </div>
          {companyConfigurations.checkincheckoutActive ? <Divider style={{ marginBottom: 20, marginTop: 10, marginRight: 25, marginLeft: 25 }} /> : null}
          <h4 style={{ paddingLeft: 30, margin: 0, marginTop: 10, marginBottom: 15, fontWeight: 'bold' }}><Entity entity="invitationTimeProfile" /></h4>
          <div style={{ paddingLeft: 30, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ marginLeft: 8, marginRight: 60 }}>
              <Entity
                componentClass={Field}
                title={<Entity entity="timeIntervalFrom" />}
                name={`${timeProfile}.timeIntervalFrom`}
                component={CustomField}
                type="antdTimePicker"
                is12HoursFormat={is12HoursFormat}
                placeholder="Select a time"
                disabled={!canEdit || areDeviceDatesLocked}
              />
            </div>
            <Entity
              componentClass={Field}
              title={<Entity entity="timeIntervalTo" />}
              name={`${timeProfile}.timeIntervalTo`}
              component={CustomField}
              is12HoursFormat={is12HoursFormat}
              type="antdTimePicker"
              placeholder="Select a time"
              disabled={!canEdit || areDeviceDatesLocked}
            />
            <div style={{ marginLeft: 30, marginTop: 20 }}>
              {_.map(timeOptions, timeOption => (
                <MDRadioButtonView
                  key={timeOption.value}
                  checked={timeOptionSelected === timeOption.value}
                  value={(timeOption.value).toString()}
                  label={timeOption.label}
                  disabled={!canEdit || areDeviceDatesLocked}
                  handleChange={value => this.onDateCheckboxChange(timeOption.value)}
                />
              ))}
            </div>
          </div>
        </Row>
        <Field
          title={<Entity entity="daysOfTheWeek" />}
          name={`${timeProfile}.daysOfTheWeek`}
          component={MDCheckBoxGroupField}
          containerstyle={{ paddingLeft: 15, marginTop: 10 }}
          horizontal
          options={daysOptions}
          disabled={!canEdit || areDeviceDatesLocked}
        />
        {companyConfigurations.holidaysActive ? (
          <div style={{ paddingLeft: 25, marginTop: 10 }}>
            <Field
              name={`${timeProfile}.holidays`}
              label={<Entity entity="includeHolidays" />}
              titleStyle={{ fontSize: 16, fontWeight: 'bold', color: '#3f3f3f' }}
              containerstyle={{ marginTop: 0 }}
              disabled={!canEdit}
              component={MDCheckBoxField}
            />
            <h4 style={{ marginTop: -10, lineHeight: 1.5, marginLeft: 35, fontSize: 15, color: '#3f3f3f', fontWeight: 'light' }}><Entity entity="includeHolidaysDescription" /></h4>
          </div>
        ) : null}
        <FieldArray
          name={`${timeProfile}.additionalTimeRange_additional`}
          timeRangesFieldName={`${timeProfile}.additionalTimeRange_additional`}
          component={onRenderTimeRange}
          formData={formData}
          form={form}
          formName={formName}
          is12HoursFormat={is12HoursFormat}
          onInitializeNewTimeRange={programId => onInitializeNewTimeRange(programId, `${timeProfile}.additionalTimeRange_additional`)}
          areDeviceDatesLocked={areDeviceDatesLocked}
          canEdit={canEdit}
        />
        {companyConfigurations.privacyOverrideActive  || companyConfigurations.credentialVegaTogglePassageModeActive ? (
          <div style={{ paddingLeft: 10 }}>
            <Divider style={{ marginBottom: 20, marginTop: 10, marginRight: 0, marginLeft: 0 }} />
            <h4 style={{ fontSize: 18, color: '#3f3f3f', fontWeight: 'bold' }}>{<Entity entity="additionalCredentialsOption" />}</h4>
            {companyConfigurations.privacyOverrideActive ? (
              <div>
                <Field
                  name={`${timeProfile}.openOnPrivacy`}
                  label={<Entity entity="privacyOverride" />}
                  titleStyle={{ fontSize: 16, fontWeight: 'bold', color: '#3f3f3f' }}
                  containerstyle={{ marginTop: 0 }}
                  disabled={!canEdit}
                  component={MDCheckBoxField}
                />
                <h4 style={{ marginTop: -10, lineHeight: 1.5, marginLeft: 35, fontSize: 15, color: '#3f3f3f', fontWeight: 'light' }}><Entity entity="privacyOverrideDescription" /></h4>
              </div>
            ) : null}
            {companyConfigurations.credentialVegaTogglePassageModeActive ? (
              <div>
                <Field
                  name={`${timeProfile}.alwaysOpen`}
                  label={<Entity entity="credentialTogglePassageMode" />}
                  titleStyle={{ fontSize: 16, fontWeight: 'bold', color: '#3f3f3f' }}
                  containerstyle={{ marginTop: 0 }}
                  component={MDCheckBoxField}
                />
                <h4 style={{ marginTop: -10, lineHeight: 1.5, marginLeft: 35, fontSize: 15, color: '#3f3f3f', fontWeight: 'light' }}><Entity entity="credentialTogglePassageModeDescription" /></h4>
            </div>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}
