import { Entity } from '@sketchpixy/rubix/lib/L20n';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import * as ModalActions from '../../../redux/actions/modal.actions.js';
import * as UserActions from '../../../redux/actions/user.actions.js';
import * as UtilsActions from '../../../redux/actions/utils.actions';
import CompanyHolidaysForm from '../../forms/CompanyHolidaysForm.jsx';
import { reset } from 'redux-form';


@connect((state) => ({ companyHolidays: state.user.companyHolidays }))
class HolidaysOperationalSection extends React.Component {

  async componentWillMount() {
    const { dispatch } = this.props;
    await dispatch(UserActions.fetchCompanyHolidays());
  }

  async onCreateNewHoliday(holidaysData) {
    const { dispatch } = this.props;
    const { holidayTimeFrame, fixed } = holidaysData;
    const holidayDTO = {
      year: holidayTimeFrame.startDate.year(),
      month: holidayTimeFrame.startDate.month() + 1,
      day: holidayTimeFrame.startDate.date(),
      duration: moment(holidayTimeFrame.endDate).diff(holidayTimeFrame.startDate, 'days') + 1,
      fixed,
    }
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(UserActions.createCompanyHoliday(holidayDTO));
      dispatch(UserActions.fetchCompanyHolidays());
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(reset('CompanyHolidaysForm'));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="settingUpdateSuccessfully" /></h6>),
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="settingUpdateError" /></h6>),
        },
      }));
    }
  }

  async onRemoveHoliday(holiday) {
    const { dispatch } = this.props;
    try {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      await dispatch(UserActions.removeCompanyHoliday(holiday.id));
      dispatch(UserActions.fetchCompanyHolidays());
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'SUCCESS_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="settingUpdateSuccessfully" /></h6>),
        },
      }));
    } catch (error) {
      dispatch(UtilsActions.setSpinnerVisibile(false));
      dispatch(ModalActions.showModal({
        modalType: 'ERROR_ALERT',
        modalProps: {
          message: (<h6 className="snack-title"><Entity entity="settingUpdateError" /></h6>),
        },
      }));
    } 
  }


  render() {
    const { dispatch, companyHolidays } = this.props;
    return (
      <div style={{ paddingLeft: 20, paddingRight: 25 }}>
        <CompanyHolidaysForm
          holidays={companyHolidays}
          onRemoveHoliday={holiday => this.onRemoveHoliday(holiday)}
          onSubmit={values => this.onCreateNewHoliday(values)}
        />
      </div>
    );
  }
} 

export default HolidaysOperationalSection;
