import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import ListViewIcon from '@material-ui/icons/ViewList';
import CardViewIcon from '@material-ui/icons/ViewStream';
import { Entity } from '@sketchpixy/rubix/lib/L20n';
import _ from 'lodash';
import moment from 'moment';
import qs from 'qs';
import React from 'react';
import { connect } from 'react-redux';
import { change, initialize, submit } from 'redux-form';
import { DEFAULT_CREDENTIAL_TIME_FRAME, LICENSE_TYPES, MATCH_TAG_MODE, PERMISSIONS, PERMISSION_ENTITIES, VIEW_MODES } from '../_config/consts';
import CredentialsListView from '../components/Credentials/CredentialsListView.jsx';
import CredentialsOperationalSection from '../components/Credentials/CredentialsOperationalSection.jsx';
import CredentialsTableView from '../components/Credentials/CredentialsTableView.jsx';
import SmartCredentialsExplanationView from '../components/Credentials/SmartCredentialsExplanationView.jsx';
import SmartPhoneKeyIconCustom from '../components/CustomIcons/SmartPhoneKeyIconCustom.jsx';
import OperationalView from '../components/OperationalView/OperationalView.jsx';
import PresentationalViewHeader from '../components/PresentationalView/PresentationalViewHeader.jsx';
import AbilityProvider from '../permissionsUtils/AbilityProvider.js';
import * as CredentialActions from '../redux/actions/credential.actions';
import * as GuestsActions from '../redux/actions/guest.actions';
import * as ModalActions from '../redux/actions/modal.actions';
import * as SettingsActions from '../redux/actions/setting.actions';
import * as TagActions from '../redux/actions/tag.actions';
import * as UtilsActions from '../redux/actions/utils.actions';


let filterTimeout;
@connect(state => ({ credentials: state.credentials, routing: state.router, languange: state.settings.language, viewLoading: state.utils.viewLoading, themeName: state.settings.items.theme.data.themeName }))
class Credentials extends React.Component {

  constructor(props) {
    super(props);
    const cachedViewMode = localStorage.getItem('credentialsViewMode');
    this.state = {
      activeTab: cachedViewMode && cachedViewMode === VIEW_MODES.CARDS ? 1 : 0,
    };
  }

  async componentWillMount() {
    const { dispatch, routing } = this.props;
    const parsed = qs.parse(routing.location.search, { ignoreQueryPrefix: true });
    dispatch(CredentialActions.selectCredential({}));
    dispatch(CredentialActions.resetCredentialFilters());
    dispatch(GuestsActions.resetGuestsFilters());
    this.onFetchCredentials();
    this.closeOperationalSection();
    dispatch(SettingsActions.fetchCompanyMetrics());
    const credentialRuleId = parsed.credentialRuleId;
    if (credentialRuleId) {
      const credential = await dispatch(CredentialActions.getCredentialRuleDetails(credentialRuleId));
      if (credential) {
        this.onSelectCredential(credential);
      }
    }
    const newEntity = parsed.newEntity;
    if (newEntity) {
      this.onNewCredentialButtonClicked();
    }

    const searchGuest = parsed.searchGuest;
    if (searchGuest) {
      try {
        const guest = JSON.parse(searchGuest);
        this.onSetFilter('guestId', guest.id);
        dispatch(change('SearchBarForm', 'guestSelected', { value: guest.id, label: `${guest.firstname} ${guest.lastname} ${guest.email}` }));
      } catch (error) {}
    }
    const newElementForGuest = parsed.newElementForGuest;
    if (newElementForGuest) {
      dispatch(UtilsActions.setSpinnerVisibile(true));
      try {
        const guestTagId = JSON.parse(newElementForGuest);
        await dispatch(CredentialActions.fetchGuestsByTags({ id: guestTagId }, 'AT_LEAST_ONE_TAG'));
        const guestTagDetails = await dispatch(TagActions.fetchGuestTagDetails(guestTagId));
        this.onNewCredentialButtonClicked();
        dispatch(change('CredentialForm', 'guestTags', guestTagDetails));
        dispatch(UtilsActions.setSpinnerVisibile(false));
      } catch (error) {
        dispatch(UtilsActions.setSpinnerVisibile(false));
      }
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(CredentialActions.selectCredential({}));
    dispatch(CredentialActions.resetCredentialFilters());
    
    dispatch(CredentialActions.setOperationalMode(false));
  }

  async onFetchCredentials() {
    const { dispatch } = this.props;
    dispatch(UtilsActions.setViewLoading(true));
    try {
      await dispatch(CredentialActions.fetchSmartphoneCredentialRules());
    } finally {
      dispatch(UtilsActions.setViewLoading(false));
    }
  }

  onNewCredentialButtonClicked() {
    const { dispatch } = this.props;
    dispatch(CredentialActions.selectCredential({}));
    dispatch(initialize('CredentialForm', {
      timeIntervalFrom: moment().startOf('day').valueOf(),
      timeIntervalTo: moment().endOf('day').valueOf(),
      dateIntervalTimeFrom: moment().startOf('day').valueOf(),
      dateIntervalTimeTo: moment().endOf('day').valueOf(),
      daysOfTheWeek: [1, 2, 3, 4, 5, 6, 7],
      lockTagMatchingMode: MATCH_TAG_MODE.AT_LEAST_ONE_TAG,
      guestTagMatchingMode: MATCH_TAG_MODE.AT_LEAST_ONE_TAG,
      credentialTimeframe: DEFAULT_CREDENTIAL_TIME_FRAME,
      holidays: true,
    }));
    dispatch(CredentialActions.setOperationalMode(true));
  }

  openOperationalSection() {
    const { dispatch } = this.props;
    dispatch(CredentialActions.setOperationalMode(true));
  }

  closeOperationalSection() {
    const { dispatch } = this.props;
    dispatch(CredentialActions.selectCredential({}));
    dispatch(CredentialActions.setOperationalMode(false));
  }

  onSetFilter(field, value) {
    const { dispatch } = this.props;
    dispatch(CredentialActions.setCredentialFilter(field, value));
    if (filterTimeout) clearTimeout(filterTimeout);
    filterTimeout = setTimeout(async () => {
      await this.onFetchCredentials();
    }, 500);
  }

  onSearchReset() {
    const { dispatch } = this.props;
    dispatch(CredentialActions.resetCredentialFilters());
    this.onFetchCredentials();
  }

  onShowInfoModal() {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONTENT_MODAL',
      modalProps: {
        title: <Entity entity="smartphoneCredentials" />,
        content: <SmartCredentialsExplanationView />,
        modalStyle: { height: '90% !important' },
        onOutsideClick: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  onExportElements(format) {
    const { dispatch } = this.props; 
    dispatch(CredentialActions.exportCredentials(format));
  }
  
  onCredentialListModeChange(activeTab) {
    const { dispatch } = this.props;
    const selectedViewMode = activeTab === 0 ? VIEW_MODES.TABLE : VIEW_MODES.CARDS;
    this.setState({ activeTab });
    CredentialActions.setCredentialsViewMode(selectedViewMode);
    if (activeTab === 0) {
      dispatch(CredentialActions.resetCredentialsPaginationData());
      dispatch(CredentialActions.fetchSmartphoneCredentialRules());
    }
  }

  onFetchCredentialsOnPage(page) {
    const { dispatch } = this.props;
    dispatch(CredentialActions.fetchSmartphoneCredentialRules(page));
  }

  async onSelectCredential(credential) {
    const { dispatch } = this.props;
    dispatch(CredentialActions.selectCredential(credential));
    try {
      // Fetch guests and locks by tag to show them when credential is selected
      await dispatch(CredentialActions.fetchLocksByTags(credential.lockTags, credential.lockTagMatchingMode));
      await dispatch(CredentialActions.fetchGuestsByTags(credential.guestTags, credential.guestTagMatchingMode));
      dispatch(CredentialActions.setOperationalMode(true));
      dispatch(initialize('CredentialForm', credential));
    } catch (error) {
      dispatch(CredentialActions.selectCredential({}));
    }
  }

  async onCreateCredentialFromModel(credential) {
    const { dispatch } = this.props;
    await dispatch(CredentialActions.fetchLocksByTags(credential.lockTags, credential.lockTagMatchingMode));
    dispatch(CredentialActions.setOperationalMode(true));
    dispatch(CredentialActions.selectCredential({}));
    const credentialTemplate = _.omit(credential, ['name', 'description', 'id', 'guestTags']);
    dispatch(initialize('CredentialForm', credentialTemplate));
  }

  onDeleteCredential(credentialId) {
    const { dispatch } = this.props;
    const params = {
      modalType: 'CONFIRM_TO_CONTINUE_MODAL',
      modalProps: {
        title: 'confirmBeforeContinue',
        message: 'deleteCredentialConfirmation',
        onConfirmText: <Entity entity="yes" />,
        onConfirm: () => dispatch(CredentialActions.deleteCredential(credentialId)),
        onCancelText: <Entity entity="no" />,
        onCancel: () => dispatch(ModalActions.hideModal()),
      },
    };
    dispatch(ModalActions.showModal(params));
  }

  render() {
    const { credentials, themeName, dispatch, viewLoading } = this.props;
    const { activeTab } = this.state;
    const canCreateCredentialRules = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.CREATE], PERMISSION_ENTITIES.CREDENTIAL_RULE);
    const canSearchGuest = AbilityProvider.getAbilityHelper().hasPermission([PERMISSIONS.READ], PERMISSION_ENTITIES.USER);
    const numberOfCredentials = credentials && credentials.data && credentials.data.pagination && credentials.data.pagination.totalElements ? credentials.data.pagination.totalElements : 0;
    const isLuckeyLite = AbilityProvider.getAbilityHelper().hasLicenseType([LICENSE_TYPES.LITE]);
    return (
      <div>
        <PresentationalViewHeader
          themeName={themeName}
          onFilterChange={value => this.onSetFilter('name', _.trim(value))}
          onNewEntity={canCreateCredentialRules ? () => this.onNewCredentialButtonClicked() : null}
          onSearchSubmit={() => this.onFetchCredentials()}
          onSearchReset={() => this.onSearchReset()}
          searchGuestContainerStyle={{ width: 280 }}
          onSelectGuest={canSearchGuest ? guest => this.onSetFilter('guestId', guest && guest.value) : null}
          newEntityTitle="addCredential"
          searchPlaceholderEntityName="credentialName"
          newEntityIconName="icon-simple-line-icons-user-following"
          isLoading={viewLoading}          
          onInfo={() => this.onShowInfoModal()}
          onExportElements={!isLuckeyLite ? format => this.onExportElements(format) : null}
          numberOfElements={numberOfCredentials}
          entitiesNumberSection={
            <div style={{ marginRight: 20, marginTop: 13, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <SmartPhoneKeyIconCustom style={{ width: 22 }} />
              <h4 style={{ marginTop: 5 }}>{numberOfCredentials}</h4>
            </div>
          }
        />
        {!isLuckeyLite && credentials && credentials.data && !_.isEmpty(credentials.data) ? (
          <div style={{ top: 140, position: 'fixed', backgroundColor: 'white' }}>
            <Tabs
              value={activeTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, index) => this.onCredentialListModeChange(index)}
              TabIndicatorProps={{
                style: { display: 'none' }
              }}
            >
              <Tab icon={<ListViewIcon style={{ fontSize: 30 }} />} />
              <Tab icon={<CardViewIcon style={{ fontSize: 30 }} />} />
            </Tabs>
          </div>
        ) : null}
        {activeTab === 0 && !isLuckeyLite ? (
          <CredentialsTableView
            onNewCredential={() => this.onNewCredentialButtonClicked()}
            onSelectCredential={credential => this.onSelectCredential(credential)}
            onRefreshCredentials={() => this.onFetchCredentials()}
            onFetchCredentialsOnPage={page => this.onFetchCredentialsOnPage(page)}
            onDeleteCredential={credentialId => this.onDeleteCredential(credentialId)}
            onCreateCredentialFromModel={credential => this.onCreateCredentialFromModel(credential)}
            onShowInfoModal={() => this.onShowInfoModal()}
          />
          ) : null}
        {activeTab === 1 || isLuckeyLite ? (
          <CredentialsListView
            listContainerStyle={{ top: isLuckeyLite ? 140 : 190 }}
            onSelectCredential={credential => this.onSelectCredential(credential)}
            onNewCredential={() => this.onNewCredentialButtonClicked()}
            onShowInfoModal={() => this.onShowInfoModal()}
          />
          ) : null}
        <OperationalView
          themeName={themeName}
          isVisible={credentials.isOperationalMode}
          onClose={() => this.closeOperationalSection()}
          title={<Entity entity="sectionTitle" data={{ name: 'credentialsOperational' }} />}
        >
          <CredentialsOperationalSection
            onCredentialFormSubmit={() => dispatch(submit('CredentialForm'))}
            onShowInfoModal={() => this.onShowInfoModal()}
            onDeleteCredential={credentialId => this.onDeleteCredential(credentialId)}
          />
        </OperationalView>
      </div>
    );
  }
} 

export default Credentials;
